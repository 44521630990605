import { useNavigate } from 'react-router-dom';
import { addUser } from '../redux/action/allActions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Constants from './Constants';

function LogOut() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        dispatch(addUser(null));

        // Local Storage
        localStorage.setItem('login',false);
        localStorage.setItem('user', null);
        localStorage.setItem('token', null);
        
        fetch("/api/auth/logout", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include"
        }).then((res) => {
            navigate(Constants.LOGIN_ROUTE, { replace: true });
            if (!res.status === 200) {
                const error = new Error(res.error);
                throw error;
            }
            
        }).catch(err => {
            console.log(err);
        });
        setLoader(false)
    });

    return (
        <>
            {loader && <div className="d-flex justify-content-center" style={{background:"(255,255,255,0)"}}>
                <div className="spinner-border text-danger" style={{width:"3rem", height:"3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}
        </>
    )

}

export default LogOut;