import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Search from "./Search/Search";
import { useDispatch } from 'react-redux';
import { searchQuery } from '../../redux/action/allActions'
import Movie from "./Movies/Movie";
import Series from "./Series/Series";
import { useSelector } from "react-redux";
import { filterSelection } from '../../redux/action/allActions'
import Authentication from "../Authentication";
import LoadingSpinner from "../LoadingSpinner";
import Constants from "../Constants";
import CommonServices from "../CommonServices";


function MovieHome() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const query = useSelector(state => state.searchReducer);
    const filter = useSelector(state => state.filterSelectionReducer)
    const [navLinkActive, setnavLinkActive] = useState(filter.catagory)
    const [loader, setLoader] = useState(true);
    const [userRole, SetUserRole] = useState();
    // let userRole = "";

    const toggleVisibility = () => {
        if (window.pageYOffset > 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const getUserRole = async (_id) => {
        let response = await CommonServices.userRole(_id);
        if (response.statusCode === 200) {
            SetUserRole(response.userRole);
        }
        else if (response.statusCode === 401) {
            navigate(Constants.LOGIN_ROUTE, { replace: true });
        }
        else {
            alert("unable to fetch user role, viewing page as normal user :)")
            SetUserRole(response.userRole);
        }
    }

    useEffect(() => {
        setLoader(true);
        let authenticationRes = Authentication({ redirectTo: Constants.DB_MOVIES_ROUTE });
        if (authenticationRes.login) {
            CommonServices.valiadteToken().then(async isValidToken => {
                if (isValidToken) {
                    setUserData(authenticationRes.user);
                    window.addEventListener("scroll", toggleVisibility);
                    getUserRole(authenticationRes.user._id);
                } else {
                    navigate(await Constants.REDIRECT(Constants.DB_MOVIES_ROUTE), { replace: true });
                }
            }).catch(err => {
                console.log(err);
            });
        }
        else {
            navigate(authenticationRes.redirectUrl, { replace: true });
        }
        setLoader(false);
    }, [])


    const onSearchChange = (e) => {
        e.preventDefault();
        dispatch(searchQuery(e.target.value));
    }

    let search = "";
    if (navLinkActive === 'search') {
        search =
            <li className="nav-item mx-2 my-auto px-0 py-0">
                <input className="form-control"
                    value={query}
                    type="search"
                    placeholder="search movies or series"
                    aria-label=""
                    autoFocus
                    onChange={onSearchChange}
                    style={{ width: "150px" }} />
            </li>
    }
    else {
        search =
            <li className="nav-item mx-2 my-1">
                <a className={navLinkActive === "search" ? "btn btn-dark" : "btn btn-outline-secondary"} href="#search" data-toggle="tab" onClick={() => setnavLinkActive("search")}>
                    🔍
                </a>
            </li>
    }

    return (
        <div>
            {
                loader ?
                    <LoadingSpinner />
                    :
                    <div>
                        <div>
                            {isVisible &&
                                <div onClick={scrollToTop}>
                                    <h1 className="mx-3 my-3" style={{
                                        position: "fixed",
                                        bottom: "5px",
                                        right: "5px",
                                        zIndex: "1030"
                                    }} > 🔝 </h1>
                                </div>}
                        </div>
                        <div style={{ margin: "1%" }}>

                            <div className="alert alert-dark" style={{ background: "rgba(255 ,255 ,255, 0.9)" }} role="alert">
                                <b className="alert-heading">Movies / TV Series</b>

                                {
                                    userRole === Constants.ADMIN_USER_ROLE || userRole === Constants.OWNER_USER_ROLE ?
                                        <button type="button" className="btn btn-outline-dark btn-sm ms-5" onClick={() => navigate(Constants.ADD_RECORD_ROUTE)} style={{ float: "right" }}>➕</button>
                                        : ""
                                }
                            </div>

                            <ul className="nav nav-pills mb-3" role="tablist" style={{ background: "rgba(255 ,255 ,255, 0.9)", borderRadius: "3px" }}>
                                <li className="nav-item mx-2 my-1">
                                    <a
                                        className={navLinkActive === "movie" ? "btn btn-dark" : "btn btn-outline-secondary"}
                                        to="#movie"
                                        data-toggle="tab"
                                        onClick={() => {
                                            // dispatch(reloadMovies())
                                            setnavLinkActive("movie")
                                            dispatch(filterSelection({
                                                ...filter, catagory: "movie"
                                            }))
                                        }
                                        }
                                    >
                                        Movies
                                    </a>
                                </li>
                                <li className="nav-item mx-2 my-1">
                                    <a
                                        className={navLinkActive === "series" ? "btn btn-dark" : "btn btn-outline-secondary"}
                                        href="#series" data-toggle="tab"
                                        onClick={() => {
                                            // dispatch(reloadMovies())
                                            setnavLinkActive("series")
                                            dispatch(filterSelection({
                                                ...filter, catagory: "series"
                                            }))
                                        }
                                        }
                                    >
                                        Series
                                    </a>
                                </li>

                                {search}

                            </ul>

                            <div className="tab-content">
                                {
                                    navLinkActive === "movie"
                                }
                                <div className={navLinkActive === "movie" ? "tab-pane active" : "tab-pane"} id="movie">
                                    {
                                        navLinkActive === "movie" ? <Movie userData={userData} userRole={userRole} /> : ""
                                    }
                                </div>
                                <div className={navLinkActive === "series" ? "tab-pane active" : "tab-pane"} id="series">
                                    {
                                        navLinkActive === "series" ? <Series userData={userData} userRole={userRole} /> : ""
                                    }
                                </div>
                                <div className={navLinkActive === "search" ? "tab-pane active" : "tab-pane"} id="search">
                                    {navLinkActive === "search" && <Search userRole={userRole} />}
                                </div>
                            </div>

                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </div >
                    </div>
            }
        </div>
    )

}

export default MovieHome;
