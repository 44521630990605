import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Constants from "../../Constants";
import LoadingSpinner from "../../LoadingSpinner";

function SeriesDetails() {

    const parseQuery = (search) => {
        search = search.split("?")[1].split("&");
        let queryParam = {};
        search = search.map((query) => {
            let key = query.split("=")[0];
            let value = query.split("=")[1];
            queryParam[key] = value;
        })
        return queryParam;
    }

    const navigate = useNavigate();
    const location = useLocation();
    const { id, watch } = parseQuery(location.search);
    const [seriesData, setSereisData] = useState("");
    const [loader, setLoader] = useState(true);
    const TMDB_API_KEY = "30061af77dba3722bbe14a2691055544";
    const [cast, setCast] = useState([]);
    const [season, setSeason] = useState({});
    const [provider, setProvider] = useState({
        "buy": [],
        "rent": [],
        "flatrate": []
    });
    // const eachSeason = seriesData.tmdbData.seasons.filter(ele => ele.name === season);
    var count = 0;

    const getMovie = async () => {
        const res = await fetch(`/api/media/series/${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            credentials: "include"
        });

        const data = await res.json();
        if (res.status === 200) {
            let series = data.result;
            if (series === "No results found") {
                // alert(series);
                navigate(Constants.DB_WORLD_HOME_ROUTE);
            }
            else {
                const castRes = await fetch(`https://api.themoviedb.org/3/tv/${series.tmdbData.id}/credits?api_key=${TMDB_API_KEY}&language=en-US`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                });

                const castData = await castRes.json();
                if (castRes.status === 200) {
                    setCast(castData.cast);
                }

                const providerRes = await fetch(`https://api.themoviedb.org/3/tv/${series.tmdbData.id}/watch/providers?api_key=${TMDB_API_KEY}`)
                const providerData = await providerRes.json();

                if (providerRes.status === 200) {
                    if (providerData && providerData.results && providerData.results.IN) {
                        var indianProvider = providerData.results.IN;

                        let provider = {
                            "buy": indianProvider && indianProvider.buy ? indianProvider.buy : [],
                            "rent": indianProvider && indianProvider.rent ? indianProvider.rent : [],
                            "flatrate": indianProvider && indianProvider.flatrate ? indianProvider.flatrate : []
                        }

                        setProvider(provider);
                    }
                }

                setSereisData(series);
                setSeason(series.tmdbData.seasons[0])
            }

        }
        else if (res.status === 401) {
            // alert("User needs to first login.")
            // alert(data.errorMessage + Constants.RE_LOGIN);
            navigate(await Constants.REDIRECT(Constants.DB_SERIES_DETIALS_ROUTE + "?id=" + id), { replace: true });
        }
        else if (res.status === 400) {
            console.log(data);
            navigate(Constants.DB_WORLD_HOME_ROUTE);
        }
        setLoader(false);
    }
    useEffect(() => {
        getMovie()
    }, []);

    if (seriesData.tmdbData) {

        var release_date = ""
        if (seriesData.tmdbData.first_air_date) {
            release_date = seriesData.tmdbData.first_air_date.split("-");
            release_date.reverse();
            release_date = release_date.join("/");
        }

    }



    return (
        loader && <LoadingSpinner />
        ||
        <div className="mx-auto" style={{ background: "rgba(255 ,255 ,255, 0.9)", maxWidth: "1000px", border: "2px Soild white" }}>

            <div className="" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>
                <div>
                    <a
                        className="mx-3 my-3"
                        style={{
                            position: "fixed",
                            bottom: "5px",
                            right: "5px",
                            zIndex: "1030"
                        }}
                        href={seriesData.downloadLink}
                    >
                        <img
                            // className="btn btn-danger btn-sm"
                            // style={{ borderRadius: "50%", width:"80%" }}
                            target="_blank"
                            src="https://img.icons8.com/external-soft-fill-juicy-fish/60/000000/external-download-essentials-soft-fill-soft-fill-juicy-fish.png" />
                    </a>
                </div>

                <div className="card bg-dark text-white" style={{
                    background: "rgba(0,0,0,0.5)",
                }}>
                    <img
                        src={seriesData.tmdbData.backdrop_path && `https://image.tmdb.org/t/p/original${seriesData.tmdbData.backdrop_path}` || `https://wallpapercave.com/dwp1x/wp3377140.jpg`}
                        className="card-img"
                        style={{
                            // height:"15rem"
                            height: "auto"
                        }}
                        alt={seriesData.name} />
                    <div className="card-img-overlay my-auto"
                        style={{
                            background: "rgba(0,0,0,0.7)",
                        }}>
                        <img
                            src={`https://image.tmdb.org/t/p/w500${seriesData.tmdbData.poster_path}`}
                            className="img-fluid"
                            alt={seriesData.name}
                            style={{
                                width: "30%",
                                boxShadow: "2px 2px 20px 10px black",
                            }} />
                        <div className="my-auto"
                            style={{
                                top: "10%",
                                left: "35%",
                                position: "absolute",
                                // position: "absolute",
                                // width:"100%"
                            }}>
                            <h5 className="card-title mx-3"><b>{seriesData.name} ({seriesData.tmdbData.first_air_date.split("-")[0]})</b></h5>
                            <div className="card-text mx-3">
                                {release_date && <span>{release_date} </span>}
                                {seriesData.tmdbData.runtime && <span><span> | </span>{Math.floor(seriesData.tmdbData.runtime / 60)}h {seriesData.tmdbData.runtime % 60}m </span> || ""}
                                <br />
                                {/* <span> | </span> */}
                                <span>
                                    {
                                        seriesData.tmdbData.genres.map(ele => {
                                            count++;
                                            if (count === seriesData.tmdbData.genres.length) {
                                                let genres = ele.name
                                                return genres
                                            }
                                            else {
                                                let genres = ele.name + ", "
                                                return genres
                                            }
                                        })
                                    }
                                </span>
                                <br />
                                <div>
                                    {
                                        seriesData.tmdbData.tagline
                                        && <p className="my-3"><q>{seriesData.tmdbData.tagline}</q></p>
                                        || <p type="text-center" className="text-center mt-3">
                                            <b className="progress w-100" style={{ border: "2px solid" }}>
                                                <span className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{ width: `${seriesData.tmdbData.vote_average * 10}%` }} aria-valuenow={seriesData.tmdbData.vote_average * 10} aria-valuemin="0" aria-valuemax="100">{seriesData.tmdbData.vote_average * 10}%</span>
                                            </b>
                                            <span>Ratting from <b>{seriesData.tmdbData.vote_count}</b> users</span>
                                        </p>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    {/* <a className="w-50" href={`https://www.imdb.com/title/${seriesData.tmdbData.imdb_id}`} target="_blank" >
                        <button className=" btn btn-warning w-50"><b>IMDB</b></button>
                    </a> */}
                    <a href={seriesData.downloadLink}
                        target="_blank"
                    >
                        <button
                            className="btn btn-danger w-100"
                        ><b>📥 Download Link 📥</b></button>
                    </a>

                </div>

                <div className="text-center my-3" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>
                    <h1 className="card-title">
                        {seriesData.name} ({seriesData.tmdbData.first_air_date.split("-")[0]})
                        &nbsp;

                    </h1>

                    {
                        seriesData.tmdbData.tagline && <>
                            <p type="text-center" className="btn text-center m-3">
                                <b className="progress w-100" style={{ border: "2px solid" }}>
                                    <span className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{ width: `${parseInt(seriesData.tmdbData.vote_average * 10)}%` }} aria-valuenow={parseInt(seriesData.tmdbData.vote_average * 10)} aria-valuemin="0" aria-valuemax="100">{parseInt(seriesData.tmdbData.vote_average * 10)}%</span>
                                </b>
                                <span>Ratting from <b>{seriesData.tmdbData.vote_count}</b> users</span>
                            </p>
                        </>
                    }
                </div>

                {
                    provider && provider.flatrate.length > 0 || provider.rent.length > 0 || provider.buy.length > 0 ?

                        <div className="mx-3">
                            <details>
                                <summary >
                                    <b style={{ fontWeight: "bold" }}> {seriesData.name} is currently available to stream, rent and buy in India. </b>
                                </summary>
                            </details>
                            <div className="m-3">

                                {
                                    provider.flatrate.length > 0 &&
                                    <div>
                                        <b>Streaming On: </b>
                                        <div style={{ overflowX: "auto", width: "60%", display: "inline-block", textWrap: "nowrap", position: "absolute" }}>
                                            {
                                                provider.flatrate.map(
                                                    flatrate => {
                                                        return (
                                                            <img
                                                                className="mx-2"
                                                                src={"https://www.themoviedb.org/t/p/original" + flatrate.logo_path}
                                                                style={{ width: "2rem" }}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                }
                                {
                                    provider.rent.length > 0 &&
                                    <div>
                                        <b>Rent On: </b>
                                        <div style={{ overflowX: "auto", width: "60%", display: "inline-block", textWrap: "nowrap", position: "absolute" }}>
                                            {
                                                provider.rent.map(
                                                    rent => {
                                                        return (
                                                            <img
                                                                className="mx-2"
                                                                src={"https://www.themoviedb.org/t/p/original" + rent.logo_path}
                                                                style={{ width: "2rem" }}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                }
                                {
                                    provider.buy.length > 0 &&
                                    <div>
                                        <b>Buy From: </b>
                                        <div style={{ overflowX: "auto", width: "60%", display: "inline-block", textWrap: "nowrap", position: "absolute" }}>
                                            {
                                                provider.buy.map(
                                                    buy => {
                                                        return (
                                                            <img
                                                                className="mx-2"
                                                                src={"https://www.themoviedb.org/t/p/original" + buy.logo_path}
                                                                style={{ width: "2rem" }}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                }
                            </div>
                        </div>
                        : ""
                }

                <div className="m-3">
                    <details>
                        <summary >
                            <b style={{ fontWeight: "bold" }}>Storyline: </b>
                        </summary>
                    </details>
                    <p className="mx-3">{seriesData.tmdbData.overview}</p>
                </div>


                <div className="mx-3 my-3">
                    <ul>
                        <li>
                            <p><b>Number Of Seasons : </b>{seriesData.tmdbData.number_of_seasons}</p>
                        </li>
                        <li>
                            <p><b>Number Of Episodes : </b>{seriesData.tmdbData.number_of_episodes}</p>
                        </li>
                    </ul>
                </div>

                <ul className="nav nav-pills mx-3 my-3" style={{ overflowX: "auto" }}>
                    <table>
                        <thead>
                            {
                                seriesData.tmdbData.seasons.map(ele => {
                                    return (
                                        <td>
                                            <button className={season.name === ele.name ? "btn btn-dark mx-2" : "btn btn-outline-secondary mx-2"}
                                                style={{ width: "100px" }}
                                                onClick={() => setSeason(ele)}>
                                                {ele.name}
                                            </button>
                                        </td>
                                    )
                                })
                            }
                        </thead>
                    </table>
                </ul>

                <div>
                    <div className="card my-3"
                    // style="max-width: 540px;"
                    >
                        <div className="row g-0">
                            <div className="col-4">
                                <img src={`https://image.tmdb.org/t/p/original${season.poster_path}`} className="img-fluid rounded-start mx-1 my-3" alt={season.name} />
                            </div>
                            <div className="col-8">
                                <div className="card-body">
                                    <h5 className="card-title">{season.name}</h5>
                                    <hr />
                                    <p className="card-text"><b>{season.name} release date : </b>{season.air_date}</p>
                                    <p className="card-text"><b>Number of episodes in {season.name} : </b>{season.episode_count}</p>
                                </div>
                            </div>
                        </div>
                        <div className="g-0">
                            {
                                season.overview &&
                                <p className="mx-3 my-3"><b>Overview : </b>{season.overview}</p>
                            }
                        </div>
                    </div>
                </div>

                <div>
                    <h5 className="mx-3">
                        <details>
                            <summary>Movie Cast</summary>
                        </details>
                    </h5>
                    <div className="nav nav-pills table-responsive mx-2" style={{ overflowX: "auto" }}>
                        <table className="mx-3">
                            <thead>
                                {
                                    cast.map(person => {
                                        if (person.profile_path) {
                                            return <td>
                                                <div className="text-center rounded-50" style={{ width: "8rem", height: "13rem" }}>
                                                    <img className="img-fluid rounded "
                                                        src={`https://image.tmdb.org/t/p/original${person.profile_path}`}
                                                        alt={person.name}
                                                        style={{ width: "8rem", height: "10rem" }}
                                                    />
                                                    {/* <br /> */}
                                                    <div className="bg-light text-left-dark md-auto rounded-bottom rounded-5" style={{ height: "3rem", overflowX: "auto" }}>
                                                        <div><b>{person.name}</b></div>
                                                        <div>{person.character}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                    })
                                }
                            </thead>
                        </table>
                    </div>
                </div>

                {
                    seriesData.tmdbData.videos.results.length !== 0
                    &&
                    <div className="border-dark mx-3 my-3" style={{ height: "13rem" }}>
                        <div>
                            <h5>
                                <details>
                                    <summary>
                                        Trailer/Teaser
                                    </summary>
                                </details>
                            </h5>
                            <ul className="nav nav-pills" style={{ overflowX: "auto" }}>
                                <table className="mx-3 my-1">
                                    <thead>
                                        {

                                            seriesData.tmdbData.videos.results.map(video => {
                                                return <td className="">
                                                    <iframe
                                                        src={`https://www.youtube.com/embed/${video.key}`}
                                                        className=""
                                                        // style={{ width: "100%" }}
                                                        allowFullScreen={true}
                                                    >
                                                    </iframe>
                                                </td>
                                            })
                                        }
                                    </thead>
                                </table>
                            </ul>

                        </div>
                    </div>
                }
            </div>
        </div>
    )


}

export default SeriesDetails;