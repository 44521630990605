import React, { useState, useRef } from 'react';

function FTPStream(props) {
    let path = props.streamPath
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(false);

    const handlePlay = () => {
        setPlaying(true);
        videoRef.current.play();
    };

    const handlePause = () => {
        setPlaying(false);
        videoRef.current.pause();
    };

    return (
        <div className="FTPStream">
            
            <video ref={videoRef} controls className='w-50 m-3'>
                <source src={`/api/ftp/download?p=${encodeURIComponent(path)}`} />
            </video>
            {/* {!playing ? (
        <button onClick={handlePlay}>Play</button>
      ) : (
        <button onClick={handlePause}>Pause</button>
      )} */}
        </div>
    );
}

export default FTPStream;
