import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Authentication from '../Authentication';
import CommonServices from '../CommonServices';
import Constants from '../Constants';

function AddPassword() {

    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [submitLoader, setSubmitLoader] = useState(false);
    const [isValidHost, setIsValidHost] = useState(true);
    let hidePasswordIcon = "https://img.icons8.com/material-rounded/24/null/hide.png";
    let visiblePasswordIcon = "https://img.icons8.com/material-rounded/24/null/visible.png";

    const [inputField, setInputField] = useState({
        host: '',
        username: '',
        password: '',
        pin: ''
    });

    const onFieldChange = (e) => {
        if (e.target.id === "host") {
            setIsValidHost(CommonServices.isValidUrl(e.target.value) ? true : false);
        }
        setInputField(
            { ...inputField, [e.target.id]: e.target.value }
        )
    }

    useEffect(() => {
        let authenticationRes = Authentication({ redirectTo: Constants.DB_ADD_PASSWORD_ROUTE });
        if (authenticationRes.login) {
            setUserData(authenticationRes.user);
        }
        else {
            navigate(authenticationRes.redirectUrl, { replace: true });
        }
    }, [])

    const togglePassword = () => {

        if (document.getElementById("password").type == "text") {
            document.getElementById("password").type = "password";
            document.getElementById("togglePassword").value = false;
            // document.getElementById("togglePasswordIcon").src = hidePasswordIcon;
        } else {
            document.getElementById("password").type = "text";;
            document.getElementById("togglePassword").value = true;
            // document.getElementById("togglePasswordIcon").src = visiblePasswordIcon;
        }
    }

    const isvalidateInputField = () => {
        if (isValidHost) {
            return true;
        } else {
            return false;
        }
    }

    const onSubmit = async (e) => {
        setSubmitLoader(true);
        e.preventDefault();
        let { host, username, password, pin } = inputField;

        if (!isvalidateInputField()) {
            toast.warning("One or more filels are incorrect");
        } else if (!host || !username || !password) {
            toast.warning("Please fill all requried fields.");
        }
        else {
            let body = {
                email: userData.email,
                userCredential: {
                    host,
                    credential: { username, password, pin }
                }
            }

            let response = await fetch(Constants.ADD_PASSWORD_API, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                body: JSON.stringify(body)
            })

            const data = await response.json();
            if (response.status === 200) {
                toast.success(data.result);
            } else if (response.status === 401) {
                toast.error(data.errorMessage,
                    {
                        autoClose: 1000,
                        onClose: () => {
                            navigate(`${Constants.LOGIN_ROUTE}?redirectTo=${Constants.DB_ADD_PASSWORD_ROUTE}`, { replace: true });
                        }
                    });
            } else {
                toast.error(data.errorMessages);
            }
        }
        setSubmitLoader(false);
    }

    return (
        <div className="card mx-3 my-2" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>
            <div className="card-header">
                <Link className='btn btn-outline-light btn-sm' to={Constants.DB_PASSWORD_MANAGER_ROUTE} style={{ float: "left" }}>
                    <img src="https://img.icons8.com/ios-glyphs/30/null/left.png" title="Go Back to Password Management" />
                </Link>
                <center><b><h2>Save Credential</h2></b></center>
            </div>
            <div className="mx-3">
                <div className="card-body">
                    <h5 className="card-title"><u>Save Credential</u></h5>
                    <ul className="card-text">
                        <li>This will Save Password In Database.</li>
                        <li>Worried about security ? No Problem, your credential will secure in our database using <b>Cypher AES Technology</b>.</li>
                        {/* <li>You will get <b>secure key</b> or you can also mack it. Using this one secure key you can access all your saved password.</li> */}
                    </ul>
                    <hr />
                    <div>
                        <form className="needs-validation">
                            <div className="form-group row mb-2">
                                <label htmlFor="host" className="col-sm-2 col-form-label">Host <span style={{ color: 'red' }}>*</span></label>
                                <div className="col-sm-5">
                                    <input type="text" className={isValidHost ? "form-control" : "form-control is-invalid"} id="host" placeholder='Ex. www.hotstar.com' value={inputField.host} onChange={onFieldChange} />
                                    <div className="invalid-feedback" htmlFor="host">
                                        <ul>
                                            <li>The URL must start with either http or https and</li>
                                            <li>Then followed by :// </li>
                                            {/* <li>then it must contain www. </li> */}
                                            <li>Then followed by subdomain of length (2, 256) </li>
                                            <li>Last part contains top level domain like .com, .org etc.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="form-group row mb-2">
                                <label htmlFor="username" className="col-sm-2 col-form-label">username <span style={{ color: 'red' }}>*</span></label>
                                <div className="col-sm-5">
                                    <input type="text" className="form-control" id="username" placeholder='username or email or mobile number' value={inputField.username} onChange={onFieldChange} />
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Password <span style={{ color: 'red' }}>*</span></label>
                                <div className="col-sm-5">
                                    <input type="password" className="form-control" id="password" placeholder="Password" value={inputField.password} onChange={onFieldChange} />
                                    {/* <img src={hidePasswordIcon} id="togglePasswordIcon" style={{ marginLeft: "-30px", cursor: "pointer" }} onClick={togglePassword} /> */}
                                </div>
                                <div className="form-check col-sm-3 mx-3 my-2">
                                    <input type="checkbox" className="form-check-input" id="togglePassword" placeholder="Password" value={false} onChange={togglePassword} />
                                    <label htmlFor="togglePassword" className="form-check-lable">Show Password</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Pin</label>
                                <div className="col-sm-5">
                                    <input type="password" className="form-control" id="pin" placeholder="Small Pin for mobile app login" value={inputField.pin} onChange={onFieldChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-5">
                                    <hr />
                                    {
                                        submitLoader &&
                                        <button className="btn btn-dark btn-sm" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            &nbsp;&nbsp;&nbsp;&nbsp; Submiting...
                                        </button>
                                        ||
                                        <button type="submit" className="btn btn-dark" onClick={onSubmit}>Submit</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default AddPassword;