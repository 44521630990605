import React from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import Constants from "../../Constants";
import { toast } from "react-toastify";

function MovieDetails() {

    const parseQuery = (search) => {
        search = search.split("?")[1].split("&");
        let queryParam = {};
        search = search.map((query) => {
            let key = query.split("=")[0];
            let value = query.split("=")[1];
            queryParam[key] = value;
        })
        return queryParam;
    }

    const navigate = useNavigate();
    const location = useLocation();
    const { id, watch } = parseQuery(location.search);
    const [movieData, setMovieData] = useState("");
    const [loader, setLoader] = useState(true);
    const TMDB_API_KEY = "30061af77dba3722bbe14a2691055544";
    const [cast, setCast] = useState([]);
    const [provider, setProvider] = useState({
        "buy": [],
        "rent": [],
        "flatrate": []
    });
    var count = 0;

    const getMovie = async () => {
        const res = await fetch(`/api/media/movie/${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            credentials: "include"
        });
        console.log(res);
        const data = await res.json();
        console.log(data);
        if (res.status === 200) {

            let movie = data.result;
            if (movie === "No results found") {
                // alert(movie);
                navigate(Constants.DB_WORLD_HOME_ROUTE);
            }
            else {
                const castRes = await fetch(`https://api.themoviedb.org/3/movie/${movie.tmdbData.id}/credits?api_key=${TMDB_API_KEY}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                });

                const castData = await castRes.json();
                if (castRes.status === 200) {
                    setCast(castData.cast);
                }

                const providerRes = await fetch(`https://api.themoviedb.org/3/movie/${movie.tmdbData.id}/watch/providers?api_key=${TMDB_API_KEY}`)
                const providerData = await providerRes.json();

                if (providerRes.status === 200) {
                    if (providerData && providerData.results && providerData.results.IN) {
                        var indianProvider = providerData.results.IN;

                        let provider = {
                            "buy": indianProvider && indianProvider.buy ? indianProvider.buy : [],
                            "rent": indianProvider && indianProvider.rent ? indianProvider.rent : [],
                            "flatrate": indianProvider && indianProvider.flatrate ? indianProvider.flatrate : []
                        }

                        setProvider(provider);
                    }
                }

                setMovieData(movie);
            }
            setLoader(false);
        }
        else if (res.status === 401) {
            // alert("User needs to first login.")
            // toast.error(data.errorMessage + Constants.RE_LOGIN);
            navigate(await Constants.REDIRECT(Constants.DB_MOVIE_DETIALS_ROUTE + "?id=" + id), { replace: true });
        }
        else if (res.status === 400) {
            console.log(data);
            navigate(Constants.DB_WORLD_HOME_ROUTE);
        }
    }

    useEffect(() => {
        getMovie()
    }, []);



    if (movieData.tmdbData) {

        var release_date = ""
        if (movieData.tmdbData.release_date) {
            release_date = movieData.tmdbData.release_date.split("-");
            release_date.reverse();
            release_date = release_date.join("/");
        }

    }

    return (
        loader ? <LoadingSpinner />
            :
            <div className="mx-auto" style={{ background: "rgba(255 ,255 ,255, 0.9)", maxWidth: "1000px", border: "2px Soild white" }}>
                {watch && <>
                    <button type="button"
                        className="btn-close"
                        style={{
                            backgroundColor: "white",
                            position: "absolute",
                            top: "5rem",
                            right: "5px",
                            zIndex: "1030"
                        }}
                        onClick={() => navigate(-1)}
                    />
                    <>
                        <video className="video-fluid ms-3 mt-3 mb-3"
                            autoPlay={true}
                            loop controls muted
                            id="cspd_video"
                            poster={movieData.tmdbData.backdrop_path}
                            style={{ width: "90%" }}
                        >
                            <source src={movieData.downloadLink} />
                        </video>
                    </>

                    {/* <VideoPlayer url={movieData.downloadLink} /> */}
                </>
                    ||
                    <div className="" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>
                        <div>
                            <a
                                className="mx-3 my-3"
                                style={{
                                    position: "fixed",
                                    bottom: "5px",
                                    right: "5px",
                                    zIndex: "1030"
                                }}
                                href={movieData.downloadLink}
                            >
                                <img
                                    // className="btn btn-danger btn-sm"
                                    // style={{ borderRadius: "50%", width:"80%" }}
                                    src="https://img.icons8.com/external-soft-fill-juicy-fish/60/000000/external-download-essentials-soft-fill-soft-fill-juicy-fish.png" />
                            </a>
                        </div>


                        <div className="card bg-dark text-white" style={{
                            background: "rgba(0,0,0,0.5)",
                        }}>
                            <img
                                src={movieData.tmdbData.backdrop_path && `https://image.tmdb.org/t/p/original${movieData.tmdbData.backdrop_path}` || `https://wallpapercave.com/dwp1x/wp3377140.jpg`}
                                className="card-img"
                                style={{
                                    // height:"15rem"
                                    height: "auto"
                                }}
                                alt={movieData.name} />
                            <div className="card-img-overlay my-auto"
                                style={{
                                    background: "rgba(0,0,0,0.7)",
                                }}>
                                <img
                                    src={`https://image.tmdb.org/t/p/w500${movieData.tmdbData.poster_path}`}
                                    className="img-fluid"
                                    alt={movieData.name}
                                    style={{
                                        width: "30%",
                                        boxShadow: "2px 2px 20px 10px black",
                                    }} />
                                <div className="my-auto"
                                    style={{
                                        top: "10%",
                                        left: "35%",
                                        position: "absolute",
                                        // position: "absolute",
                                        // width:"100%"
                                    }}>
                                    <h5 className="card-title mx-3"><b>{movieData.name} ({movieData.tmdbData.release_date.split("-")[0]})</b></h5>
                                    <div className="card-text mx-3">
                                        {release_date && <span>{release_date} </span>}
                                        {movieData.tmdbData.runtime && <span><span> | </span>{Math.floor(movieData.tmdbData.runtime / 60)}h {movieData.tmdbData.runtime % 60}m </span> || ""}
                                        <br />
                                        {/* <span> | </span> */}
                                        <span>
                                            {
                                                movieData.tmdbData.genres.map(ele => {
                                                    count++;
                                                    if (count === movieData.tmdbData.genres.length) {
                                                        let genres = ele.name
                                                        return genres
                                                    }
                                                    else {
                                                        let genres = ele.name + ", "
                                                        return genres
                                                    }
                                                })
                                            }
                                        </span>
                                        <br />
                                        <div>
                                            {
                                                movieData.tmdbData.tagline
                                                && <p className="my-3"><q>{movieData.tmdbData.tagline}</q></p>
                                                || <p type="text-center" className="text-center mt-3">
                                                    <b className="progress w-100" style={{ border: "2px solid" }}>
                                                        <span className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{ width: `${movieData.tmdbData.vote_average * 10}%` }} aria-valuenow={movieData.tmdbData.vote_average * 10} aria-valuemin="0" aria-valuemax="100">{movieData.tmdbData.vote_average * 10}%</span>
                                                    </b>
                                                    <span>Ratting from <b>{movieData.tmdbData.vote_count}</b> users</span>
                                                </p>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="text-center">
                            <a className="w-50" href={`https://www.imdb.com/title/${movieData.tmdbData.imdb_id}`} target="_blank" >
                                {/* <img type="button" className=" btn btn-warning my-1 mx-1" src="https://upload.wikimedia.org/wikipedia/commons/6/69/IMDB_Logo_2016.svg"
                                style={{ 
                                    // width: "3.5rem",
                                 float: "left" }}
                                data-placement="top" title="IMDB Link"
                            /> */}
                                <button className=" btn btn-warning w-50"><b>IMDB</b></button>
                            </a>
                            <Link to={`${location.pathname + location.search}&watch=true`}>
                                <button
                                    className="btn btn-danger w-50"
                                ><b>▶ Play Movie</b></button>
                            </Link>

                        </div>
                    </div>}

                <div className="card-body text-center">
                    <h1 className="card-title">
                        {movieData.name} ({movieData.tmdbData.release_date.split("-")[0]})
                        &nbsp;

                    </h1>

                    {
                        movieData.tmdbData.tagline && <>
                            <p type="text-center" className="btn text-center m-3">
                                <b className="progress w-100" style={{ border: "2px solid" }}>
                                    <span className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{ width: `${parseInt(movieData.tmdbData.vote_average * 10)}%` }} aria-valuenow={parseInt(movieData.tmdbData.vote_average * 10)} aria-valuemin="0" aria-valuemax="100">{parseInt(movieData.tmdbData.vote_average * 10)}%</span>
                                </b>
                                <span>Ratting from <b>{movieData.tmdbData.vote_count}</b> users</span>
                            </p>
                        </>
                    }
                </div>

                {
                    provider && provider.flatrate.length > 0 || provider.rent.length > 0 || provider.buy.length > 0 ?

                        <div className="mx-3">
                            <details>
                                <summary >
                                    <b style={{ fontWeight: "bold" }}> {movieData.name} is currently available to rent and buy in India. </b>
                                </summary>
                            </details>
                            <div className="m-3">

                                {
                                    provider.flatrate.length > 0 &&
                                    <div>
                                        <b>Streaming On: </b>
                                        <div style={{ overflowX: "auto", width: "60%", display: "inline-block", textWrap: "nowrap", position: "absolute" }}>
                                            {
                                                provider.flatrate.map(
                                                    flatrate => {
                                                        return (
                                                            <img
                                                                className="mx-2"
                                                                src={"https://www.themoviedb.org/t/p/original" + flatrate.logo_path}
                                                                style={{ width: "2rem" }}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                }
                                {
                                    provider.rent.length > 0 &&
                                    <div>
                                        <b>Rent On: </b>
                                        <div style={{ overflowX: "auto", width: "60%", display: "inline-block", textWrap: "nowrap", position: "absolute" }}>
                                            {
                                                provider.rent.map(
                                                    rent => {
                                                        return (
                                                            <img
                                                                className="mx-2"
                                                                src={"https://www.themoviedb.org/t/p/original" + rent.logo_path}
                                                                style={{ width: "2rem" }}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                }
                                {
                                    provider.buy.length > 0 &&
                                    <div>
                                        <b>Buy From: </b>
                                        <div style={{ overflowX: "auto", width: "60%", display: "inline-block", textWrap: "nowrap", position: "absolute" }}>
                                            {
                                                provider.buy.map(
                                                    buy => {
                                                        return (
                                                            <img
                                                                className="mx-2"
                                                                src={"https://www.themoviedb.org/t/p/original" + buy.logo_path}
                                                                style={{ width: "2rem" }}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                }
                            </div>
                        </div>
                        : ""

                }

                <div className="m-3">
                    <details>
                        <summary >
                            <b style={{ fontWeight: "bold" }}>Storyline: </b>
                        </summary>
                    </details>
                    <p className="mx-3">{movieData.tmdbData.overview}</p>
                </div>

                <div>
                    <h5 className="mx-3">
                        <details>
                            <summary>Movie Cast</summary>
                        </details>
                    </h5>
                    <div className="nav nav-pills table-responsive mx-2" style={{ overflowX: "auto" }}>
                        <table className="mx-3">
                            <thead>
                                <tr>
                                    {
                                        cast.map(person => {
                                            if (person.profile_path) {
                                                return <td>
                                                    <div className="text-center rounded-50" style={{ width: "8rem", height: "13rem" }}>
                                                        <img className="img-fluid rounded "
                                                            src={`https://image.tmdb.org/t/p/original${person.profile_path}`}
                                                            alt={person.name}
                                                            style={{ width: "8rem", height: "10rem" }}
                                                        />
                                                        {/* <br /> */}
                                                        <div className="bg-light text-left-dark md-auto rounded-bottom rounded-5" style={{ height: "3rem", overflowX: "auto" }}>
                                                            <div><b>{person.name}</b></div>
                                                            <div>{person.character}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            }
                                        })
                                    }
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                {
                    movieData.tmdbData.videos.results.length !== 0
                    &&
                    <div className="border-dark mx-3 my-3" style={{ height: "13rem" }}>
                        <div>
                            <h5>
                                <details>
                                    <summary>
                                        Trailer/Teaser
                                    </summary>
                                </details>
                            </h5>
                            <ul className="nav nav-pills" style={{ overflowX: "auto" }}>
                                <table className="mx-3 my-1">
                                    <thead>
                                        <tr>
                                            {
                                                movieData.tmdbData.videos.results.map(video => {
                                                    return <td className="">
                                                        <iframe
                                                            src={`https://www.youtube.com/embed/${video.key}`}
                                                            className=""
                                                            // style={{ width: "100%" }}
                                                            allowFullScreen={true}
                                                        >
                                                        </iframe>
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                </table>
                            </ul>
                        </div>
                    </div>
                }

                <div className="row mx-1 my-5">
                    <div className="col-5" style={{ height: "12.5rem", overflowY: "auto", border: "1px Solid" }}>
                        <p className="mt-2"><b>Status: </b> {movieData.tmdbData.status}</p>
                        <p><b>Original Language: </b> {movieData.tmdbData.original_language}</p>
                        <p><b>Budget: </b>{movieData.tmdbData.budget !== 0 ? <>{movieData.tmdbData.budget.toLocaleString()} $</> : "-"}</p>
                        <p><b>Revenue: </b>{movieData.tmdbData.revenue !== 0 ? <>{movieData.tmdbData.revenue.toLocaleString()} $</> : "-"}</p>
                    </div>

                    <div className="col-7" style={{ height: "12.5rem", overflowY: "auto", border: "1px Solid" }}>
                        <p className="text-center mt-2"><b>Production Companies</b></p>
                        <hr />
                        <div style={{ listStyle: "none" }}>
                            {
                                movieData.tmdbData.production_companies.map(ele => {
                                    return <li className="mx-0">
                                        <details>
                                            <summary>
                                                <b>{ele.name}</b>
                                            </summary>
                                            {
                                                ele.logo_path && <img
                                                    className="card-img-top mx-3"
                                                    src={`https://image.tmdb.org/t/p/w200${ele.logo_path}`}
                                                    alt={ele.name}
                                                    style={{ borderRadius: "5%", width: "5rem" }} />
                                            }
                                        </details>
                                    </li>
                                })
                            }
                        </div>
                    </div>
                </div>
                <br />
            </div >



    )

}

export default MovieDetails;