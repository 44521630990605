import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import SingleMovie from '../SingleMovie';
import LoadingSpinner from '../../LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import SearchInDrive from './SearchInDrive';
import Constants from '../../Constants';

function Search(props) {

    // const userList = useSelector(state => state.userReducer.userList);
    const userRole = props.userRole;
    const query = useSelector(state => state.searchReducer);
    const [searchMovieList, setSearchMovieList] = useState("");
    const reload = useSelector(state => state.reloadMoviesReducer)
    const [loading, setLoading] = useState(true);
    const [movieId, setMovieId] = useState();
    const [setVideo, setSetVideo] = useState(false);
    const [modelParameter, setModelParameter] = useState({
        downloadLink: "",
        name: ""
    });
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')));
    const navigate = useNavigate();
    const [searchInDrive, setSearchInDrive] = useState(false);

    const searchMovie = async () => {
        try {
            const res = await fetch("/api/media/search", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ query })
            })

            const data = await res.json();
            console.log(data);
            if (res.status === 200) {
                if (data.results.length == 0) {
                    setSearchMovieList("");
                }
                else {
                    setSearchMovieList(data.results);
                }
            }
            else if (res.status === 401) {
                alert(data.errorMessage + Constants.RE_LOGIN);
                navigate(await Constants.REDIRECT());
            }
            else {
                console.log(data);
            }
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        searchMovie();
    }, [query, reload])

    if (searchMovieList.length == 0) {
        var search =
            <div className="alert alert-warning" role="alert">
                Sorry, No Result Found for : <span className="alert-link">{query}</span>
            </div>
    }
    else {
        var search =
            searchMovieList.map((movie, index) => {
                var query = movie.name.replace(":", "")
                query = query.replace("-", "")
                query = query.replace(".", " ")

                return (
                    <SingleMovie
                        movie={movie}
                        tmdbQurey={query}
                        userData={userData}
                        key={movie.id}
                        id={index}
                        userRole={userRole}
                    />
                )
            })
    }

    return (
        <div>

            <div className="form-check form-switch mx-1 py-1 rounded-pill" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>
                <div className='mx-5'>
                    <input className="form-check-input" type="checkbox" id="searchInDriveChecked" searchInDrive
                        onClick={() => setSearchInDrive(!searchInDrive)}
                    />
                    <label className="form-check-label" htmlFor="fsearchInDriveChecked"><b>Search In Drive </b></label>
                </div>
            </div>

            <div className='mt-2'>
                {
                    !searchInDrive ?
                        <div>
                            <h4 className='rounded-pill' style={{ textAlign: "center", border: "2px solid", padding: "1%", background: "rgba(255 ,255 ,255, 0.9)", marginBottom: "1%" }}>Search List</h4>
                            {
                                !loading ? <div className="row row-cols-1 row-cols-md-4 g-4">
                                    {search}
                                </div> :
                                    <LoadingSpinner />
                            }
                        </div>
                        :
                        <div className="m-1" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>
                            <SearchInDrive />
                        </div>
                }
            </div>
        </div>
    )
}

export default Search;