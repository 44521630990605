import React, { useEffect, useState } from 'react';
import SearchInDrive from '../DB_Movies/Search/SearchInDrive';
import Status from './Status';
import Authentication from '../Authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import UserList from '../DB_Users/UserList';
import Constants from '../Constants';
import queryString from 'query-string';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SystemInfo from './SystemInfo';
import DownloadStuf from './DownloadStuf';
import FtpManager from './FtpManager';
import CommonServices from '../CommonServices';
import AddRecord from './AddRecord';
import UserRole from './UserRole';
import { useDispatch } from 'react-redux';
import { findAllUsers } from '../../redux/action/allActions';
import { findAllUsersService } from '../ApiServices';
import UsersData from './UsersData';
import { Form } from 'react-bootstrap';

function AdminTools() {

    const dispatch = useDispatch();
    const [freeMemoryLoder, setFreeMemoryLoder] = useState(false);
    const [loader, setLoader] = useState(true);
    const [mainLoader, setMainLoader] = useState(true)
    const [userData, setUserData] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const [key, setKey] = useState('download');
    const [userRole, SetUserRole] = useState();
    const [tableView, setTableView] = useState(false);

    const tabActiveClassName = 'nav-pills btn-sm bg-dark text-white rounded-3 m-2'
    const tabClassName = 'nav-pills btn-sm bg-white border-1 border-dark rounded-3 text-dark m-2'

    const checkUserRole = async (_id) => {
        let response = await CommonServices.userRole(_id);
        if (response.statusCode === 200) {
            SetUserRole(response.userRole);
            if (response.userRole !== Constants.OWNER_USER_ROLE && response.userRole !== Constants.ADMIN_USER_ROLE) {
                alert("You don't have admin rights.")
                navigate(Constants.DB_WORLD_HOME_ROUTE);
            }
            else {
                if (location.hash.length !== 0) {
                    let hash = queryString.parse(location.hash);
                    setKey(hash.active)
                }

            }
        }
        else if (response.statusCode === 401) {
            navigate(Constants.REDIRECT(Constants.DB_ADMIN_TOOLS_ROUTE + `${location.hash.length !== 0 ? "#active=" + queryString.parse(location.hash).active : ""}`), { replace: true });
        }
        else {
            alert("unable to fetch user role, viewing page as normal user :)")
            navigate(Constants.DB_WORLD_HOME_ROUTE)
        }
    }

    const getAllUsers = async () => {
        const response = await findAllUsersService();
        dispatch(findAllUsers(response.data));
    }

    const setHasKey = () => {
        if (location.hash.length !== 0) {
            let hash = queryString.parse(location.hash);
            setKey(hash.active)
        }
        setLoader(false);
    }

    const postProcessAfterAuthenticatiion = async (authenticationRes) => {
        setUserData(authenticationRes.user);
        checkUserRole(authenticationRes.user._id);
        await getAllUsers();
        setHasKey();
        setMainLoader(false);
    }

    useEffect(() => {
        CommonServices.valiadteToken().then(async isValidToken => {
            let redirectUrl = await Constants.REDIRECT(Constants.DB_ADMIN_TOOLS_ROUTE + `${location.hash.length !== 0 ? "#active=" + queryString.parse(location.hash).active : ""}`)
            if (isValidToken) {
                let authenticationRes = Authentication({ redirectTo: redirectUrl });
                if (authenticationRes.login) {
                    postProcessAfterAuthenticatiion(authenticationRes)
                } else navigate(authenticationRes.redirectUrl, { replace: true });
            } else navigate(redirectUrl)
        }).catch(err => {
            console.log(err);
        });
    }, [])

    useEffect(() => {
        setHasKey();
    }, [location])

    return (
        <div className="card m-1" style={{ background: "rgba(255 ,255 ,255, 0.9)" }}>

            {
                mainLoader ? Constants.LOADER :
                <div>
                    <ul className="nav nav-pills mb-3 bg-light" role="tablist">

                        <li className="nav-item mx-2 my-1">
                            {
                                !freeMemoryLoder ?
                                <button
                                    className="btn btn-outline-dark"
                                >
                                    Free Memory
                                </button>
                                :
                                <button className="btn btn-dark" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;&nbsp;&nbsp;Processing ...
                                </button>
                            }
                        </li>
                    </ul>

                    <div className=''>
                        <Tabs
                            defaultActiveKey="Download"
                            id="controlled-tab"
                            className="mb-3 flex-nowrap bg-light"
                            transition={true}
                            // onMouseOut={()=>setLoader(false)}
                            activeKey={key}
                            onSelect={(k) => {
                                setLoader(true)
                                navigate(`${Constants.DB_ADMIN_TOOLS_ROUTE}#active=${k}`)
                            }}
                            style={{ overflowX: "auto" }}
                        >
                            <Tab className='m-3' eventKey="user_data" title="User_Data" tabClassName={key === 'user_data' ? tabActiveClassName : tabClassName}>
                                <Form>
                                    <Form.Switch // prettier-ignore
                                        type="switch"
                                        id="table_view"
                                        label="Table View On/Off"
                                        checked={tableView}
                                        onChange={() => setTableView(!tableView)}
                                    />
                                </Form>
                                {loader ? Constants.LOADER : key === 'user_data' && !tableView ? <UsersData /> : <UserList />}
                            </Tab>
                            <Tab className='m-3' eventKey="user_role" title="User_Role" tabClassName={key === 'user_role' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'user_role' && <UserRole userData={userData} />}
                            </Tab>
                            <Tab className='m-3' eventKey="add_record" title="Add_Record" tabClassName={key === 'add_record' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'add_record' && <AddRecord />}
                            </Tab>
                            <Tab className='m-3' eventKey="search" title="Search" tabClassName={key === 'search' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'search' && <SearchInDrive />}
                            </Tab>
                            <Tab className='m-3' eventKey="download" title="Download" tabClassName={key === 'download' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'download' && <DownloadStuf />}
                            </Tab>
                            <Tab className='m-3' eventKey="status" title="Status" tabClassName={key === 'status' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'status' && <Status />}
                            </Tab>
                            <Tab className='m-3' eventKey="system" title="System" tabClassName={key === 'system' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'system' && <SystemInfo />}
                            </Tab>
                            <Tab className='m-3' eventKey="ftp" title="FTP" tabClassName={key === 'ftp' ? tabActiveClassName : tabClassName}>
                                {loader ? Constants.LOADER : key === 'ftp' && <FtpManager />}
                                {/* <FTPStream /> */}
                                {/* <Directory /> */}
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            }


        </div>
    )

}

export default AdminTools;