import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import CommonServices from '../CommonServices';
import axios from 'axios';
import FTPStream from './FTPStream';
import { v1 as uuidv1 } from 'uuid';

const FolderTree = () => {
    const [currentPath, setCurrentPath] = useState('/');
    const [foldersAndFiles, setFoldersAndFiles] = useState([]);
    const [cache, setCache] = useState({});
    const [loading, setLoading] = useState(false);
    const [videoId, setVideoId] = useState("");

    const getFtpList = async (path = "/") => {
        let res = await axios.get(`/api/ftp/list?p=${encodeURIComponent(path)}`);
        // console.log(res);
        // const data = await res.json();
        if (res.status === 200) {
            return res.data.result;
        } else {
            // console.log(res.data)
            return null;
        }
    }


    const downloadFile = async (item) => {
        const response = await axios({
            url: `/api/ftp/download?p=${encodeURIComponent(item.path)}`,
            method: 'GET',
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // console.log(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.name);
        document.body.appendChild(link);
        link.click();

    }

    useEffect(() => {
        const fetchData = async () => {
            if (cache[currentPath]) {
                setFoldersAndFiles(cache[currentPath]);
                return;
            }
            setLoading(true);
            const result = await getFtpList(currentPath);
            setLoading(false);
            if (result !== null) {
                const parsedResult = result.reduce((acc, cur) => {
                    cur['type'] = cur.type === 2 || cur.type === 'd' ? "folder" : "file";
                    cur['path'] = `${currentPath}/${cur.name}`;
                    cur['id'] = `${currentPath}/${cur.name}`;
                    acc.foldersAndFiles.push(cur);
                    return acc;
                }, { foldersAndFiles: [] });
                setCache({ ...cache, [currentPath]: parsedResult.foldersAndFiles });
                setFoldersAndFiles(parsedResult.foldersAndFiles);
            } else {
                // alert("failed to load details")
            }
        };
        fetchData();
    }, [currentPath, cache]);

    const handleItemClick = (item) => {
        if (item.type === 'folder') {
            setCurrentPath(item.path);
        }
    };

    const handleBackButton = () => {
        const path = currentPath.split('/');
        path.pop();
        const newPath = path.join('/');
        setCurrentPath(newPath === '' ? '/' : newPath);
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <button onClick={() => handleItemClick({ type: "folder", path: currentPath })} className="btn btn-dark mb-3">Refresh</button>
            {currentPath !== '/' && (
                <button onClick={handleBackButton} className="btn btn-primary mb-3">Back</button>
            )}
            {currentPath}
            {loading ? (
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            ) : (
                <ul className="list-group w-100 m-2">
                    {foldersAndFiles.map((item) => (
                        <li key={item.path || item.name} className="list-group-item">
                            <span className='fw-bold' onClick={() => handleItemClick(item)} style={{ cursor: "pointer" }}>
                                {item.type === 'folder' ? "📂" : "📄"}
                                {item.name}
                                {item.type === 'file' ? " || " + CommonServices.bytesToReadbleFormat(item.size).value + " " + CommonServices.bytesToReadbleFormat(item.size).suffix : ""}
                                {/* {item.type === 'file' ?
                                    <span> || <button onClick={() => setVideoId(item.id)} className='btn btn-sm'>Play ▶️</button> </span>
                                    : ""}
                                {item.id === videoId && item.type === 'file' ?
                                    <FTPStream streamPath={item.path} /> : ""
                                } */}
                            </span>
                        </li>
                    ))}
                </ul>
            )
            }
        </div>
    )
}

export default FolderTree;
