import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Link, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from './LoadingSpinner';
import Authentication from './Authentication';
import Constants from './Constants';
import db_world_icon from '../images/db_world_teal.svg';
import CommonServices from './CommonServices';


function Header() {
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [login, setLogin] = useState(false);
    const userReducer = useSelector(state => state.userReducer);
    const [userRole, SetUserRole] = useState();

    const getHeaderName = () => {
        let pathname = location.pathname;
        let headername = ""
        if (pathname === Constants.DB_GAMES_ROUTE) {
            headername = "Games"
        } else if (pathname === Constants.DB_WEATHER_ROUTE) {
            headername = "Weather & Time"
        } else if (pathname.includes(Constants.DB_PASSWORD_MANAGER_ROUTE)) {
            headername = "Password Manager"
        } else if (pathname.includes(Constants.DB_MOVIES_ROUTE)) {
            headername = "Cinema"
        } else headername = "DB World"
        return headername;
    }

    const getUserRole = async (_id) => {
        let response = await CommonServices.userRole(_id);
        if (response.statusCode === 200) {
            SetUserRole(response.userRole);
        }
        else if (response.statusCode === 401) {
            navigate(Constants.LOGIN_ROUTE, { replace: true });
        }
        else {
            alert("unable to fetch user role, viewing page as normal user :)")
            SetUserRole(response.userRole);
        }
    }

    useEffect(() => {
        setLoader(true);
        CommonServices.valiadteToken().then(async isValidToken => {
            if (isValidToken) {
                let authenticationRes = Authentication({ redirectTo: Constants.DB_WORLD_HOME_ROUTE });
                if (authenticationRes.login) {
                    setUserData(authenticationRes.user);
                    setLogin(authenticationRes.login);
                    getUserRole(authenticationRes.user._id);
                }
            }
        }).catch(err => {
            console.log(err);
        });
        setLoader(false);
    }, [userReducer])

    var user = "";

    if (login) {
        user = <>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={Constants.DB_WORLD_HOME_ROUTE}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={Constants.DB_WEATHER_ROUTE} tabIndex="-1" aria-disabled="true">Weather & Time</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={Constants.DB_PASSWORD_MANAGER_ROUTE} tabIndex="-1" aria-disabled="true">Password Management</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={Constants.DB_MOVIES_ROUTE} tabIndex="-1" aria-disabled="true">DB Cinema</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={Constants.DB_GAMES_ROUTE} tabIndex="-1" aria-disabled="true">Games</Link>
                    </li>
                </ul>
                <ul className="nav navbar-nav navbar-right me-5">
                    <li className="nav-item dropdown me-5">
                        <Link className="nav-link dropdown-toggle" to={Constants.USER_PROFILE_ROUTE} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            👱‍♂️ {userData.firstName}
                        </Link>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><Link className="dropdown-item" to={Constants.USER_PROFILE_ROUTE}>My Profile</Link></li>
                            {
                                userRole === Constants.OWNER_USER_ROLE || userRole === Constants.ADMIN_USER_ROLE ?
                                    <li><Link className="dropdown-item" to={Constants.DB_ADMIN_TOOLS_ROUTE}>Admin Tools</Link></li>
                                    : ""
                            }
                            <li><Link className="dropdown-item" to={Constants.LOGOUT_ROUTE}>Logout</Link></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    }
    else {
        user =
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link active" aria-current="page" to={Constants.DB_WORLD_HOME_ROUTE}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={Constants.REGISTRATION_ROUTE}>Registration 📃</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={Constants.LOGIN_ROUTE}>Login 🔐</Link>
                    </li>
                </ul>
            </div>
    }

    return (
        !loader &&
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={Constants.DB_WORLD_HOME_ROUTE}>
                        <img src={db_world_icon} className="d-inline-block align-top rounded-circle" width="50" height="50" style={{ backgroundColor: "rgb(203, 237, 232)" }} />
                    </Link>
                    <Link className="navbar-brand" to={location.pathname + location.search}>
                        <h5 className="d-inline-block align-top"> {getHeaderName()} </h5>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {user}
                </div>
            </nav>
        </div>
        ||
        <LoadingSpinner />
    )
}

export default Header;