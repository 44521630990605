import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Constants from '../Constants';
import { updateUserRoleService } from '../ApiServices';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserRole = ({ userData }) => {

    const users = useSelector(state => state.userReducer.users);
    const [loader, setLoader] = useState(true);
    const [doer_id, setDoer_id] = useState(userData._id);
    const [for_id, setFor_id] = useState(doer_id);
    const [userRole, setUserRole] = useState("");
    const [submitLoader, setSubmitLoader] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (users && users.length > 1) {
            setLoader(false);
        }
    }, [users])

    const onSubmit = async () => {
        setSubmitLoader(true);
        let response = await updateUserRoleService(doer_id, for_id, userRole)
        if (response && response.status === 200) {
            toast.success(response.data.result);
        }
        else if (response.status === 401) {
            navigate(Constants.REDIRECT(Constants.ADMIN_USER_ROLE));
        } else {
            // console.log(response.data);
            toast.error(response.data.errorMessages);
        }
        setSubmitLoader(true);
    }


    return (
        <div className="bg-transparent pb-5">
            {
                loader ? Constants.LOADER :
                    <Card className='m-3' border="dark" style={{ width: '18rem' }}>
                        <Card.Header>Change User Role</Card.Header>
                        <Card.Body>
                            <Card.Title>Select User</Card.Title>
                            <Card.Text>
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setFor_id(e.target.value)} defaultValue={doer_id}>
                                    <option disabled >Open this select menu</option>
                                    {
                                        users.map(user => {
                                            return <option value={user._id} >
                                                {user.firstName} {user.lastName} | {user.email}
                                            </option>
                                        })
                                    }
                                </select>
                            </Card.Text>
                            <Card.Title>Select Role</Card.Title>
                            <Card.Text>
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setUserRole(e.target.value)}>
                                    <option >Open this select menu</option>
                                    <option value={Constants.OWNER_USER_ROLE} >Owner</option>
                                    <option value={Constants.ADMIN_USER_ROLE} >Admin</option>
                                    <option value={Constants.VIEWER_USER_ROLE} >Viewer</option>
                                </select>
                            </Card.Text>
                            <Card.Footer>
                                <Button type='button' onClick={onSubmit}>Submit</Button>
                            </Card.Footer>
                        </Card.Body>
                    </Card>

            }
            {Constants.TOAST_CONTAINER}
        </div>
    )
}

export default UserRole;