import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Constants from '../Constants';
import LoadingSpinner from '../LoadingSpinner';
import SystemInfo from './SystemInfo';

function Status() {

    const [status, setStatus] = useState([])
    const [loading, setLoading] = useState(false);
    const [liveUpdate, setLiveUpdate] = useState(false);
    const [intervalNumber, setIntervalNumber] = useState();
    const navigate = useNavigate();

    const getStatus = async () => {
        // setLoading(true);
        console.log("in get status")
        try {
            const res = await fetch("/api/media/status", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include"
            })

            const data = await res.json();

            if (res.status === 200) {
                setStatus(data.result);
                setLoading(false)
            } else if (res.status === 401) {
                toast.error(data.errorMessage + Constants.RE_LOGIN, {
                    onClose: async () => {
                        navigate(await Constants.REDIRECT(Constants.DB_ADMIN_TOOLS_ROUTE + "#active=status"));
                    },
                    autoClose: 1000
                })
            }
            else {
                toast.error(data.errorMessage);
            }

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (liveUpdate) {
            let interval = setInterval(async () => {
                setIntervalNumber(interval);
                await getStatus();
            }, 6000);
        }
        else {
            clearInterval(intervalNumber);
        }
    }, [liveUpdate])

    useEffect(() => {
        getStatus();
    }, [])


    const deleteStatus = async (id) => {

        try {
            const res = await fetch(`/api/media/status/delete?id=${id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                credentials: "include"
            })

            const data = await res.json();

            if (res.status === 200) {
                toast.success("Status Deleted.");
                getStatus();
            }
            else {
                toast.error(data.errorMessage);
            }

        } catch (err) {
            console.log(err);
            toast.error("Failed.");
        }

    }

    const bytesToReadbleFormat = (bytes) => {

        var megabytes = bytes * 0.00000095367432;
        var kilobytes = bytes * 0.00097656;
        var gigabytes = megabytes * 0.00097656;

        if (bytes < 1024) {
            return `${bytes} bytes`
        }
        else if (kilobytes > 1 && kilobytes < 1024) {
            return `${parseFloat(kilobytes).toFixed(2)} KB`
        }
        else if (megabytes < 1024) {
            return `${parseFloat(megabytes).toFixed(2)} MB`
        }
        else if (megabytes > 1024) {
            return `${parseFloat(gigabytes).toFixed(2)} GB`
        }
    }

    // function getDownloadPercentage(downloadFileSize, downloadFileSizeFormat, totalFileSize, totalFileSizeFormat) {
    //     let percentage = 0
    //     if (downloadFileSizeFormat === totalFileSizeFormat) {
    //         percentage = ((downloadFileSize * 100) / totalFileSize).toFixed(2)
    //     }
    //     else if (downloadFileSizeFormat === "MB" && totalFileSizeFormat === "GB") {
    //         totalFileSize *= 1024
    //         percentage = ((downloadFileSize * 100) / totalFileSize).toFixed(2)
    //     } else if (downloadFileSizeFormat === "GB" && totalFileSizeFormat === "MB") {
    //         downloadFileSizeFormat *= 1024
    //         percentage = ((downloadFileSize * 100) / totalFileSize).toFixed(2)
    //     }
    //     return percentage;
    // }

    function getPercentage(actual, total) {
        let percentage = parseFloat((actual * 100) / total).toFixed(2)
        return percentage;
    }


    return (
        <div className="card bg-transparent">

            {
                !loading &&
                <div>

                    <div className="row mx-3 my-3">
                        <div className="col-6 form-check form-switch d-flex">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                onClick={() => setLiveUpdate(!liveUpdate)}
                            />
                            <label className="form-check-label mx-3" htmlFor="flexSwitchCheckDefault">Live Update</label>
                        </div>
                        <div className="col-6 justify-content-end d-flex">
                            <button className='btn btn-warning'
                                onClick={() => getStatus()}
                            >Refresh Status</button>
                        </div>
                    </div>

                    {
                        status.length === 0 ?
                            <h4 className='text-warning text-center my-5'>Currently no task is running.</h4>
                            :
                            status.map((stats, key) => {
                                return (
                                    <div className="card mx-3 my-3">
                                        <span className="btn btn-danger position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                                            onClick={() => deleteStatus(stats.statusId)}
                                        >
                                            🗑
                                            <span className="visually-hidden">Delete</span>
                                        </span>
                                        <div className="card-header">
                                            <b>{stats.name}</b>
                                        </div>
                                        <div className="card-body">
                                            <blockquote className="blockquote mb-0">

                                                {
                                                    stats.isDownload &&
                                                    <div>
                                                        <p><b>Status : </b>{stats.status}</p>
                                                        <p><b>Message : </b>{stats.message}</p>
                                                        <p>
                                                            <div className="row">
                                                                <div className="col-4 col-md-2">
                                                                    <b>Process : </b>
                                                                </div>
                                                                <div className="col-8 col-md-4">
                                                                    <div className="progress" style={{ width: "70%" }}>
                                                                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-success text-dark" role="progressbar"
                                                                            aria-valuemin="0"
                                                                            aria-valuenow={stats.downloadState.percentage ? parseFloat(stats.downloadState.percentage).toFixed(2) : getPercentage(stats.downloadState.downloadFileSize, stats.downloadState.totalFileSize)}
                                                                            aria-valuemax="100"
                                                                            style={{ width: stats.downloadState.percentage ? `${parseFloat(stats.downloadState.percentage).toFixed(2)}%` : `${getPercentage(stats.downloadState.downloadFileSize, stats.downloadState.totalFileSize)}%` }}
                                                                        >
                                                                            <b>{stats.downloadState.percentage ? parseFloat(stats.downloadState.percentage).toFixed(2) : getPercentage(stats.downloadState.downloadFileSize, stats.downloadState.totalFileSize)} % </b>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </p>
                                                        <p><b>Downloaded Size : </b>{bytesToReadbleFormat(stats.downloadState.downloadFileSize)}</p>
                                                        <p><b>Remaining Size : </b>{bytesToReadbleFormat(stats.downloadState.remaining)}</p>
                                                        {stats.downloadState.speed && <p><b>Current Speed : </b>{bytesToReadbleFormat(stats.downloadState.speed)}/s</p>}
                                                        {stats.downloadState.eta && <p><b>ETA : </b>{stats.downloadState.eta}</p>}
                                                        {stats.downloadState && stats.downloadState.totalFileSize && <p><b>Total Size : </b>{bytesToReadbleFormat(stats.downloadState.totalFileSize)}</p>}
                                                        {stats.sourceUrl && <p><b>Source Link : </b><a href={stats.sourceUrl}>link</a></p>}
                                                    </div>
                                                }
                                                {
                                                    stats.isUpload &&
                                                    <div>
                                                        <p><b>Status : </b>{stats.status}</p>
                                                        {stats.uploadState && <p><b>Uploading State : </b>{stats.uploadState}</p>}
                                                        {stats.downloadState && stats.downloadState.totalFileSize ? <p><b>Total Size : </b>{bytesToReadbleFormat(stats.downloadState.totalFileSize)}</p> : ""}
                                                        {stats.sourceUrl && <p><b>Source Link : </b><a href={stats.sourceUrl}>link</a></p>}
                                                    </div>
                                                }
                                                {
                                                    stats.isExtract &&
                                                    <div>
                                                        <p><b>Status : </b>{stats.status}</p>
                                                        <p><b>Extract Method : </b>{stats.extractMethod}</p>
                                                        <p><b>Message : </b>{stats.message}</p>
                                                        {stats.downloadState && stats.downloadState.totalFileSize ? <p><b>Total Size : </b>{bytesToReadbleFormat(stats.downloadState.totalFileSize)}</p> : ""}
                                                        {stats.sourceUrl && <p><b>Source Link : </b><a href={stats.sourceUrl}>link</a></p>}
                                                    </div>
                                                }
                                                {
                                                    stats.isFailed &&
                                                    <div>
                                                        <p><b>Status : </b>{stats.status}</p>
                                                        <p><b>Message : </b>{stats.message}</p>
                                                        {stats.downloadState && stats.downloadState.totalFileSize ? <p><b>Total Size : </b>{bytesToReadbleFormat(stats.downloadState.totalFileSize)}</p> : ""}
                                                        {stats.sourceUrl && <p><b>Source Link : </b><a href={stats.sourceUrl}>link</a></p>}
                                                    </div>
                                                }
                                                {
                                                    stats.isComplete &&
                                                    <div>
                                                        <p><b>Status : </b>{stats.status}</p>
                                                        {stats.downloadState && stats.downloadState.totalFileSize ? <p><b>Total Size : </b>{bytesToReadbleFormat(stats.downloadState.totalFileSize)}</p> : ""}
                                                        {stats.isExtract && <p><b>Extract Method : </b>{stats.extractMethod}</p>}
                                                        {stats.sourceUrl && <p><b>Source Link : </b><a href={stats.sourceUrl}>link</a></p>}
                                                        <p><b>Drive Link : </b><a href={stats.driveLink}>link</a></p>
                                                        <p><b>Download Link : </b><a href={stats.downloadLink}>link</a></p>
                                                    </div>
                                                }
                                                {
                                                    (!stats.isDownload && !stats.isUpload && !stats.isExtract && !stats.isComplete && !stats.isFailed) ?
                                                        <div>
                                                            <p><b>Status : </b>{stats.status}</p>
                                                            <p><b>Message : </b>{stats.message}</p>
                                                            {stats.uploadState && <p><b>Uploading State : </b>{stats.uploadState}</p>}
                                                            {stats.downloadState && stats.downloadState.totalFileSize ? <p><b>Total Size : </b>{bytesToReadbleFormat(stats.downloadState.totalFileSize)}</p> : ""}
                                                            {stats.sourceUrl && <p><b>Source Link : </b><a href={stats.sourceUrl}>link</a></p>}
                                                        </div>
                                                        : ""
                                                }
                                            </blockquote>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
                ||
                <LoadingSpinner />
            }


            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )

}

export default Status;