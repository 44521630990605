import React, { useEffect, useState } from 'react';
import { Button, Card, CardGroup, Col, Form, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CommonServices from '../CommonServices';
import Constants from '../Constants';
import { ToastContainer } from 'react-toastify';
import EditProfile from '../DB_Users/EditProfile';

const UsersData = () => {

    const users = useSelector(state => state.userReducer.users);
    const [userData, setUserData] = useState(useSelector(state => state.userReducer.users));
    const DOWN_ARROW_ICON = "https://img.icons8.com/ios-glyphs/90/sort-down.png";
    const UP_ARROW_ICON = "https://img.icons8.com/ios-glyphs/90/sort-up.png";
    const [expandUser, setExpandUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [loader, setLoader] = useState(true);

    const onSearchQueryChange = (query) => {
        setUserData(
            query === "" || query === null || typeof (query) === "undefined" ? users :
                users.filter(({ _id, email, firstName, lastName, mobileNo }) =>
                    _id.toLowerCase().includes(query.toLowerCase()) ||
                    email.toLowerCase().includes(query.toLowerCase()) ||
                    firstName.toLowerCase().includes(query.toLowerCase()) ||
                    lastName.toLowerCase().includes(query.toLowerCase()) ||
                    mobileNo.toString().toLowerCase().includes(query.toLowerCase())
                )
        )
        return userData;
    }

    useEffect(() => {
        onSearchQueryChange(searchQuery);
        setLoader(
            users && users.length && users.length >= 0 ? false : true
        )
    }, [users, searchQuery])

    const editUserModel = (editModelId) => {

        let user = users.filter(({ _id }) => _id === expandUser);
        console.log(user);
        if (user && user.length && user.length === 1) {
            user = user[0];
            return (
                <div className={`modal fade`} id={editModelId} tabindex="-1" aria-labelledby={editModelId} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={editModelId}>Update {user.firstName} Information</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <Form>

                                    <Form.Group as={Row} className="mb-3" controlId="email">
                                        <Form.Label column sm={2}>Email</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="email" defaultValue={user.email} plaintext disabled readOnly />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="firstName">
                                        <Form.Label column sm={2}>First Name</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" placeholder={user.firstName}
                                                value={""}
                                                onChange={""} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="lastName">
                                        <Form.Label column sm={2}>Last Name</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" placeholder={user.lastName} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="mobileNo">
                                        <Form.Label column sm={2}>Contact Number</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" placeholder={user.mobileNo} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="dob">
                                        <Form.Label column sm={2}>DOB</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="date" placeholder={user.dob} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="gender">
                                        <Form.Label column sm={2}>Gender</Form.Label>
                                        <Col sm={10}>
                                            <Form.Check
                                                type="radio"
                                                label="Male"
                                                name="gender"
                                                id="male"
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="Female"
                                                name="gender"
                                                id="female"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="password">
                                        <Form.Label column sm={2}>Site Password</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" placeholder={user.password} />
                                        </Col>
                                    </Form.Group>

                                </Form>
                            </div>
                            <div className="modal-footer">
                                <Button type="submit" className="btn btn-primary btn-sm ">Update Details</Button>
                                <button type="button" className="btn btn-secondary btn-sm " data-bs-dismiss="modal">Close</button>
                                {/* {
                                    updateLoader &&
                                    <button className="btn btn-danger btn-sm" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;&nbsp;&nbsp;&nbsp; Updating...
                                    </button>
                                    ||
                                    <button type="button" className="btn btn-danger btn-sm" onClick={onUpdateCredential}>Update</button>
                                } */}
                            </div>
                        </div>
                    </div>
                    <ToastContainer
                        containerId={`toast_`}
                        position="top-right"
                        autoClose={1000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            )
        }
        else {
            return "No User Found."
        }


    }

    return (
        <div className="bg-transparent pb-5">

            {
                loader ? Constants.LOADER :
                    <div>
                        <nav className="navbar navbar-light justify-content-end">
                            <form className="form-inline">
                                <input className="form-control border rounded-pill" type="search" aria-label="Search" placeholder="Search With any keyword" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            </form>
                        </nav>


                        <Row xs={1} md={expandUser === null ? 3 : 3} className="g-4">
                            {userData.map((user, idx) => {
                                return (
                                    <Col key={idx}>

                                        <div className="accordion" id="user_info">
                                            <Card>
                                                <Card.Header id={`heading${user._id}`}>
                                                    <div className="position-relative">
                                                        <button className="position-absolute top-0 end-0 btn btn-link collapsed p-0"
                                                            data-toggle="collapse"
                                                            data-target={`#collapse${user._id}`}
                                                            aria-expanded="false"
                                                            aria-controls={`collapse${user._id}`}
                                                        >
                                                            <img width="30" height="30"
                                                                src={expandUser === user._id ? UP_ARROW_ICON : DOWN_ARROW_ICON}
                                                                alt={expandUser === user._id ? "sort-up" : "sort-down"}
                                                                onClick={() => setExpandUser(expandUser === user._id ? null : user._id)} />
                                                        </button>

                                                    </div>
                                                    <div className="mb-0 ms-3">
                                                        <div className="position-absolute top-0 start-0 rounded bg-dark text-white p-1">
                                                            {idx + 1}
                                                        </div>
                                                        <b>{user.firstName} {user.lastName}</b>
                                                    </div>
                                                </Card.Header>
                                                {
                                                    expandUser === user._id ? "" : <Card.Body>
                                                        <Card.Text>
                                                            <Table responsive="sm">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>id:</th>
                                                                        <td>{user._id}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Email:</th>
                                                                        <td>{user.email}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Contact No:</th>
                                                                        <td>{user.mobileNo}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Card.Text>
                                                    </Card.Body>
                                                }


                                                <div id={`collapse${user._id}`} className={expandUser === user._id ? "collapse show" : "collapse"} aria-labelledby={`heading${user._id}`} data-parent="#user_info">
                                                    {
                                                        expandUser === user._id &&
                                                        <div>
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <Table responsive="sm">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>id:</th>
                                                                                <td>{user._id}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>First Name:</th>
                                                                                <td>{user.firstName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Last Name:</th>
                                                                                <td>{user.lastName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Email:</th>
                                                                                <td>{user.email}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Contact No:</th>
                                                                                <td>{user.mobileNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Gender:</th>
                                                                                <td>{user.gender}</td>
                                                                            </tr>
                                                                            {
                                                                                user.dob ?
                                                                                    <tr>
                                                                                        <th>DOB:</th>
                                                                                        <td>{user.dob}</td>
                                                                                    </tr>
                                                                                    :
                                                                                    <tr>
                                                                                        <th>Age:</th>
                                                                                        <td>{user.age}</td>
                                                                                    </tr>
                                                                            }
                                                                            <tr>
                                                                                <th>No. of login</th>
                                                                                <td>{user.loginNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Site Password:</th>
                                                                                <td>{user.password}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>User Role:</th>
                                                                                <td>{user.userRole}</td>
                                                                            </tr>
                                                                            {
                                                                                user.userLoginDetails && user.userLoginDetails.length > 0 ?
                                                                                    <tr>
                                                                                        <th>User Last 5 Login:</th>
                                                                                        <td>
                                                                                            {user.userLoginDetails.map((userLoginDetail, idx) => {
                                                                                                if (typeof (userLoginDetail.timeStamp) !== 'object' && idx < 5) {
                                                                                                    return (
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                {CommonServices.getTimeDateFromTimeStamp(userLoginDetail.timeStamp).date} | {CommonServices.getTimeDateFromTimeStamp(userLoginDetail.timeStamp).time}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                }
                                                                                            })}
                                                                                        </td>
                                                                                    </tr>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </Card.Text>
                                                            </Card.Body>
                                                            <Card.Footer>
                                                                <div className="btn-toolbar justify-content-end">
                                                                    <button className="btn btn-warning btn-sm mx-2" type="button" data-bs-toggle="modal" data-bs-target={`#editModel${user._id}`}

                                                                    >Edit</button>
                                                                    <button className="btn btn-danger btn-sm mx-2" type="button" data-bs-toggle="modal" data-bs-target={`#deleteModel${user._id}`}

                                                                    >Delete</button>

                                                                    {editUserModel(`editModel${user._id}`)}

                                                                </div>
                                                            </Card.Footer>
                                                        </div>
                                                    }
                                                </div>
                                            </Card>
                                        </div>
                                    </Col>
                                )
                            })

                            }
                        </Row>
                    </div>
            }


        </div >
    )
}

export default UsersData