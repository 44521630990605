import React, { useEffect, useState } from 'react';
import JSONView from 'react-json-view'
import CommonServices from '../CommonServices';

const SystemInfo = () => {

  const [systemData, setSystemData] = useState({});
  const [loder, setLoder] = useState(true);

  const convertObjectToRedable = (sysInfo) => {
    sysInfo.ram.availableSize = CommonServices.bytesToReadbleFormat(sysInfo?.ram?.availableSize).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.ram?.availableSize).suffix;
    sysInfo.ram.totalSize = CommonServices.bytesToReadbleFormat(sysInfo?.ram?.totalSize).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.ram?.totalSize).suffix;
    sysInfo.ram.usedSize = CommonServices.bytesToReadbleFormat(sysInfo?.ram?.usedSize).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.ram?.usedSize).suffix;

    sysInfo.rom.availableSize = CommonServices.bytesToReadbleFormat(sysInfo?.rom?.availableSize).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.rom?.availableSize).suffix;
    sysInfo.rom.totalSize = CommonServices.bytesToReadbleFormat(sysInfo?.rom?.totalSize).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.rom?.totalSize).suffix;
    sysInfo.rom.usedSize = CommonServices.bytesToReadbleFormat(sysInfo?.rom?.usedSize).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.rom?.usedSize).suffix;

    sysInfo.time.current = CommonServices.getTimeDateFromTimeStamp(sysInfo?.time?.current)

    sysInfo.networkSpeed.downloadSpeed = CommonServices.bytesToReadbleFormat(sysInfo?.networkSpeed?.downloadSpeed).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.networkSpeed?.downloadSpeed).suffix;
    sysInfo.networkSpeed.uploadSpeed = CommonServices.bytesToReadbleFormat(sysInfo?.networkSpeed?.uploadSpeed).value
      + " " + CommonServices.bytesToReadbleFormat(sysInfo?.networkSpeed?.uploadSpeed).suffix;

    return sysInfo;
  }

  async function getSystemInfo() {
    let res = await fetch("/api/system/systemInfo", {
      method: "GET"
    })
    const data = await res.json();
    if (res.status === 200) {
      setSystemData(convertObjectToRedable(data.result.systemInfo));
    }
    else {
      console.log(data);
    }
    setLoder(false);
  }

  useEffect(() => {
    getSystemInfo();
  }, [])


  return (
    <div className='mx-3'>
      {
        loder && <div className="col-md-8">
          <div className='d-flex justify-content-center'>
            <div className="spinner-border text-danger m-5" role="status">
              <span className="sr-only text-center" />
            </div>
          </div>
        </div>
        ||
        // <JSONView src={systemData} />
        <CommonServices.JSONToHTMLTable data={systemData} style={{ overflowX: "auto", width: "20rem", display: "block" }} />
      }
    </div>
  )

}

export default SystemInfo;