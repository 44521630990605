import axios from 'axios'
import Constants from './Constants'

export const findAllUsersService = async () => {
    return await axios.get(Constants.FIND_ALL_USERS_API)

}

export const updateUserRoleService = async (doer_id, for_id, userRole) => {
    try {
        let response = await axios.get(`${Constants.UPDATE_USER_ROLE_API}?doer_id=${doer_id}&for_id=${for_id}&userRole=${userRole}`)
        return response;
    }
    catch (error) {
        return error.response;
    }
}

export const loadDbCinemaRecords = async (filter, recordsPageNumberList) => {

    var api = "";
    var disPageNumber = 0;
    if (filter.catagory === "movie") {
        if (filter.movieIndustry === "all") {
            api = `/api/media/movie?industry=${filter.movieIndustry}&page=${recordsPageNumberList.all}`
            disPageNumber = recordsPageNumberList.all;
        }
        else if (filter.movieIndustry === "bollywood") {
            api = `/api/media/movie?industry=${filter.movieIndustry}&page=${recordsPageNumberList.bollywood}`
            disPageNumber = recordsPageNumberList.bollywood;
        }
        else if (filter.movieIndustry === "hollywood") {
            api = `/api/media/movie?industry=${filter.movieIndustry}&page=${recordsPageNumberList.hollywood}`
            disPageNumber = recordsPageNumberList.hollywood;
        }
        else if (filter.movieIndustry === "south") {
            api = `/api/media/movie?industry=${filter.movieIndustry}&page=${recordsPageNumberList.south}`
            disPageNumber = recordsPageNumberList.south;
        }
        else if (filter.movieIndustry === "gujarati") {
            api = `/api/media/movie?industry=${filter.movieIndustry}&page=${recordsPageNumberList.gujarati}`
            disPageNumber = recordsPageNumberList.gujarati;
        }
    }
    else if (filter.catagory === "series") {
        if (filter.seriesIndustry === "all") {
            api = `/api/media/series?industry=${filter.seriesIndustry}&page=${recordsPageNumberList.all}`
            disPageNumber = recordsPageNumberList.all;
        }
        else if (filter.seriesIndustry === "bollywood") {
            api = `/api/media/series?industry=${filter.seriesIndustry}&page=${recordsPageNumberList.bollywood}`
            disPageNumber = recordsPageNumberList.bollywood;
        }
        else if (filter.seriesIndustry === "hollywood") {
            api = `/api/media/series?industry=${filter.seriesIndustry}&page=${recordsPageNumberList.hollywood}`
            disPageNumber = recordsPageNumberList.hollywood;
        }
        else if (filter.seriesIndustry === "south") {
            api = `/api/media/series?industry=${filter.seriesIndustry}&page=${recordsPageNumberList.south}`
            disPageNumber = recordsPageNumberList.south;
        }
        else if (filter.seriesIndustry === "gujarati") {
            api = `/api/media/series?industry=${filter.seriesIndustry}&page=${recordsPageNumberList.gujarati}`
            disPageNumber = recordsPageNumberList.gujarati;
        }
    }

    const response = await axios.get(api);
    if (response && response.data && response.data.status && response.data.statusCode === 200) {
        response.data.result = { ...response.data.result, disPageNumber }
        return response.data;
    }
    return null;

}