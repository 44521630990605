import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom"
import { reloadMovies } from "../../redux/action/allActions"
import Constants from "../Constants";
import LoadingSpinner from "../LoadingSpinner";
import CommonServices from "../CommonServices";

function SingleMovie(props) {
    const movie = props.movie;
    const userData = props.userData;
    const userRole = props.userRole;
    const id = props.id;
    const [deleteRecord, setDeleteRecord] = useState();
    const [setTrailer, setSetTrailer] = useState(false);
    const [loader, setLoader] = useState(false);
    var deleteModelTargetSrc = "#deleteMovieId" + id;
    var deleteModelTargetDes = "deleteMovieId" + id;
    var trailerModelTargetDes = "trailerMovieId" + new Date();
    var trailerModelTargetSrc = "#" + trailerModelTargetDes;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let count = 0;

    const newlyAdded = (recodAddedTimeStamp) => {

        let newlyAdded = false;

        let recordDate = new Date();
        recordDate.setTime(recodAddedTimeStamp)

        let currentDate = new Date();
        let timeDifference = Math.abs(currentDate - recordDate);

        const daysDiffence = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) - 1;

        if (daysDiffence <= 4) {
            newlyAdded = true; // 3days diffence
        }
        return newlyAdded;
    }

    if (movie.tmdbData) {

        trailerModelTargetSrc = "#trailerMovieId" + movie.tmdbData.id;
        trailerModelTargetDes = "trailerMovieId" + movie.tmdbData.id;

        var ratting = ""
        if (movie.tmdbData.vote_average / 2 >= 0 && movie.tmdbData.vote_average / 2 < 0.5) {
            ratting = "🚫"
        }
        else if (movie.tmdbData.vote_average / 2 >= 0.5 && movie.tmdbData.vote_average / 2 <= 1.5) {
            ratting = "⭐"
        }
        else if (movie.tmdbData.vote_average / 2 >= 1.5 && movie.tmdbData.vote_average / 2 <= 2.5) {
            ratting = "⭐⭐"
        }
        else if (movie.tmdbData.vote_average / 2 >= 2.5 && movie.tmdbData.vote_average / 2 <= 3.5) {
            ratting = "⭐⭐⭐"
        }
        else if (movie.tmdbData.vote_average / 2 >= 3.5 && movie.tmdbData.vote_average / 2 <= 4.5) {
            ratting = "⭐⭐⭐⭐"
        }
        else if (movie.tmdbData.vote_average / 2 >= 4.5 && movie.tmdbData.vote_average / 2 <= 5) {
            ratting = "⭐⭐⭐⭐⭐"
        }

        var ytTrailerLink = ""
        if (movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "hi").length >= 1) {
            ytTrailerLink = movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "hi")
        } else if (movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "en").length >= 1) {
            ytTrailerLink = movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "en")
        } else if (movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "te").length >= 1) {
            ytTrailerLink = movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "te")
        } else if (movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "ta").length >= 1) {
            ytTrailerLink = movie.tmdbData.videos.results.filter(ele => ele.iso_639_1 === "ta")
        }
        if (ytTrailerLink) {
            if (ytTrailerLink.filter(ele => ele.type === "Trailer").length === 0) {
                if (ytTrailerLink.filter(ele => ele.type === "Teaser").length === 0) {
                    ytTrailerLink = `https://www.youtube.com/embed/${ytTrailerLink[0].key}`
                } else {
                    ytTrailerLink = `https://www.youtube.com/embed/${ytTrailerLink.filter(ele => ele.type === "Teaser")[0].key}`
                }
            } else {
                ytTrailerLink = `https://www.youtube.com/embed/${ytTrailerLink.filter(ele => ele.type === "Trailer")[0].key}`
            }
        }

        var release_date = ""
        if (movie.tmdbData.release_date) {
            release_date = movie.tmdbData.release_date.split("-");
            release_date.reverse();
            release_date = release_date.join("/");
        }
        else if (movie.tmdbData.first_air_date) {
            release_date = movie.tmdbData.first_air_date.split("-");
            release_date.reverse();
            release_date = release_date.join("/");
        }
    }

    async function onDelete() {
        try {
            // console.log(deleteRecord)
            const res = await fetch("/api/media/delete/record", {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                credentials: "include",
                body: JSON.stringify(deleteRecord)
            })
            const data = await res.json();
            // console.log(data);
            if (res.status === 200) {
                alert("Deleted Successfully.")
                dispatch(reloadMovies());
            }
            else if (res.status === 401) {
                alert(data.errorMessage + Constants.RE_LOGIN)
                navigate(await Constants.REDIRECT());
            }
            else {
                alert(data.errorMessage);
            }
        } catch (err) {
            console.log(err);
            alert(err);
        }
    }

    const trailer =
        <>
            <iframe
                src={ytTrailerLink}
                width="100%"
                height="220rem"
                allowFullScreen={true}
            ></iframe>
        </>

    let singleMovie = ""

    if (movie.tmdbData) {
        singleMovie =
            <div className="col d-flex align-items-stretch my-3" >

                {/* Movie Home Card */}
                <div className="card w-100 my-1" style={{ background: "rgba(255 ,255 ,255, 0.6)", height: "19rem", }}
                >
                    <div className="d-flex align-items-stretch" >

                        {movie.tmdbData.backdrop_path && <div >
                            <img src={`https://image.tmdb.org/t/p/w500${movie.tmdbData.backdrop_path}`} alt={movie.name}
                                style={{ height: "100%", width: "100%", position: "absolute", zIndex: "-1" }}
                            />
                        </div> || ""}

                        <div className="container mx-1 mt-1">
                            <div className="row">

                                {/* Movie Name */}
                                <div className="col mt-2">
                                    <h6 className="table-responsive mb-0">
                                        {movie.name}
                                    </h6>
                                </div>

                                {
                                    userRole === Constants.ADMIN_USER_ROLE || userRole === Constants.OWNER_USER_ROLE ?

                                        /* Movie Delete Button */
                                        < div className="col-2">
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target={deleteModelTargetSrc} onClick={() => setDeleteRecord({ id: movie.id, category: movie.category })}>🗑</button>
                                        </div> : ""
                                }

                                {
                                    userRole === Constants.ADMIN_USER_ROLE || userRole === Constants.OWNER_USER_ROLE ?

                                        /* Movie Edit Button */
                                        <div className="col-2 ">
                                            <Link type="button" className="btn btn-success btn-sm"
                                                to={Constants.EDIT_RECORD_ROUTE + "?_id=" + movie._id}
                                                state={movie}
                                            >📝</Link>
                                        </div> : ""
                                }

                                {
                                    newlyAdded(movie.id) ? <div>
                                        <span className="position-absolute top-0 end-0 translate-middle-y badge rounded-pill bg-primary">
                                            New
                                            <span className="visually-hidden">newly added</span>
                                        </span>
                                    </div> : ""
                                }
                            </div>

                            {/* Delete Movie Model */}
                            <div className="modal fade" id={deleteModelTargetDes} tabIndex="-1" aria-labelledby={deleteModelTargetDes} aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id={deleteModelTargetDes}>Conform Delete ?</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            You want to delete this movie?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => onDelete()} >
                                                Yes, Delete it!
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />


                    <div className="table-responsive">
                        <div className="d-flex align-items-stretch mx-3 my-1">

                            {/* Movie Image */}
                            <div className="flex-shrink-0"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate((movie.category.toLowerCase() === "movie" ? Constants.DB_MOVIE_DETIALS_ROUTE : Constants.DB_SERIES_DETIALS_ROUTE) + `?id=${movie.id}`)}
                            >
                                <img src={`https://image.tmdb.org/t/p/w500${movie.tmdbData.poster_path}`} alt={movie.name}
                                    style={{ width: "130px", height: "200px", float: "left" }}
                                />
                            </div>

                            {/* Movie Details */}
                            <div className="d-grid gap-0 d-md-flex-row mx-3">
                                <span><b>Release: </b>
                                    {movie.category.toLowerCase() === "movie" ? movie.tmdbData.release_date : movie.tmdbData.first_air_date}
                                </span>
                                {
                                    movie.category.toLowerCase() === "movie" ?
                                        <span><b>Runtime: </b> {Math.floor(movie.tmdbData.runtime / 60) + "h " + movie.tmdbData.runtime % 60 + "m"} </span>
                                        :
                                        <span><b>No. Of Seasons: </b>{movie.tmdbData.number_of_seasons}</span>
                                }
                                <span> <b>Geners: </b>
                                    {
                                        movie.tmdbData.genres.map(ele => {
                                            count++;
                                            if (count === movie.tmdbData.genres.length) {
                                                let genres = ele.name
                                                return genres
                                            }
                                            else {
                                                let genres = ele.name + ", "
                                                return genres
                                            }
                                        })
                                    }
                                </span>
                                <span className="card-text"><b style={{ fontWeight: "bold" }}>Ratting: </b> {ratting}</span>
                                <div>
                                    <span>
                                        <img type="button" src="https://img.icons8.com/color/48/000000/youtube-play.png"
                                            data-bs-toggle="modal"
                                            data-bs-target={trailerModelTargetSrc}
                                            data-placement="top" title="Watch Trailer On Youtube"
                                            onClick={() => setSetTrailer(true)}
                                        />
                                        {movie.category === "Movie" && <a href={`https://www.imdb.com/title/${movie.tmdbData.imdb_id}`} target="_blank" >
                                            <img type="button" src="https://upload.wikimedia.org/wikipedia/commons/6/69/IMDB_Logo_2016.svg"
                                                style={{ width: "3.5rem" }}
                                                data-placement="top" title="IMDB Link"
                                            />
                                        </a>}
                                        {movie.tmdbData.adult && <img type="button" src="https://img.icons8.com/color/48/000000/18-plus.png" />}
                                        <img type="button" title="Click for more details" alt="more details"
                                            onClick={() => navigate((movie.category.toLowerCase() === "movie" ? Constants.DB_MOVIE_DETIALS_ROUTE : Constants.DB_SERIES_DETIALS_ROUTE) + `?id=${movie.id}`)}
                                            style={{ width: "35px" }} src="https://img.icons8.com/ios-filled/50/000000/info.png" />
                                    </span>
                                </div>


                            </div>

                            {/* youtube trailer modal */}
                            <div className="modal fade" id={trailerModelTargetDes} tabIndex="-1" aria-labelledby={trailerModelTargetDes} aria-hidden="true">
                                {loader && <LoadingSpinner /> || <div className="modal-dialog">
                                    {!ytTrailerLink && <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="trailerModelTargetDes">{movie.name}</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={
                                                () => setSetTrailer(false)
                                            }></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="alert alert-warning text-center">
                                                <b className="border-bottom">⚠ No Trailer or Video Found For This Movie. ⚠</b></div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div> ||
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="trailerModelTargetDes">{movie.name}</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={
                                                    () => setSetTrailer(false)
                                                }></button>
                                            </div>
                                            <div className="modal-body">
                                                {
                                                    setTrailer && trailer
                                                }
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSetTrailer(false)}>Close</button>
                                            </div>
                                        </div>
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
    }

    else {
        singleMovie =
            <div className="col d-flex align-items-stretch my-3" >

                {/* Movie Home Card */}
                <div className="card w-100 my-1" style={{ background: "rgba(255 ,255 ,255, 0.6)", height: "22rem", }}>
                    <div className="d-flex align-items-stretch" >

                        <div className="container mx-1 mt-1">
                            <div className="row">

                                {/* Movie Name */}
                                <div className="col mt-2">
                                    <h6 className="table-responsive mb-0">
                                        {movie.name}
                                    </h6>
                                </div>

                                {
                                    userRole === Constants.ADMIN_USER_ROLE || userRole === Constants.OWNER_USER_ROLE ?

                                        /* Movie Delete Button */
                                        < div className="col-2">
                                            <button type="button" className="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target={deleteModelTargetSrc} onClick={() => setDeleteRecord({ id: movie.id, category: movie.category })}>🗑</button>
                                        </div> : ""
                                }

                                {
                                    userRole === Constants.ADMIN_USER_ROLE || userRole === Constants.OWNER_USER_ROLE ?

                                        /* Movie Edit Button */
                                        <div className="col-2 ">
                                            <Link type="button" className="btn btn-success btn-sm"
                                                to={Constants.EDIT_RECORD_ROUTE + "?_id=" + movie._id}
                                                state={movie}
                                            >📝</Link>
                                        </div> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    }

    return (
        <div className="" style={{ marginTop: "1%" }}>
            {/* {movie.category === "Movie" ? singleMovie : singleSeries} */}
            {singleMovie}
        </div>
    )
}

export default SingleMovie;